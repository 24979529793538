import { ref } from 'vue';
import { Modal } from 'bootstrap';


const modalManager =  {
    modalManagerEventTarget: new EventTarget(),
    savedModals: ref([]),
    saveCloseAllModals: function(){
        // Find all visible modals
        const visibleModals = Array.from(document.querySelectorAll('.modal.show'));
        visibleModals.forEach((modalElement) => {
            console.log(modalElement)
            this.savedModals.value.push(modalElement)
            this.savedModals.value.push(null)
            const modalInstance = Modal.getInstance(modalElement);
            modalInstance.hide();
        })
    },
    saveAndCloseModal: function(modal){
        this.savedModals.value.push(modal)
        this.savedModals.value.push(null)
        let modalInstance = Modal.getOrCreateInstance(modal)
        modalInstance.hide()
    },

    restoreModal: function (){
        const modal = this.savedModals.value.pop()
        if(modal){
            Modal.getOrCreateInstance(modal).show();
        }else if(modal === undefined){
            this.modalManagerEventTarget.dispatchEvent(new CustomEvent('all-modals-closed'))
        }
    }
};


export default modalManager;
